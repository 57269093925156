<template src="./template.html"></template>

<script>
import {isPossiblePhoneNumber, isValidPhoneNumber, parsePhoneNumber} from "libphonenumber-js";

export default {
  name: "change-profile",
  data(){
    return{
      name: null,
      phone: null,
      organisation: null,
      backTo: null
    }
  },
  async mounted() {
    await this.$auth.currentAuthenticatedUser().then(async (user) => {
      this.$store.commit('saveUserData', user)
      console.log('User is: ', user)
      this.name = user.attributes ? user.attributes.name : null
      this.phone = user.attributes ? user.attributes.phone_number : null
      if(this.phone && this.phone.length > 0){
        const phoneNumber = parsePhoneNumber(this.phone)
        let isValid = isValidPhoneNumber(this.phone, phoneNumber.country) === true
        let message = isValid ? '' : 'Invalid phone number'
        this.$refs.phoneNum.setValidity(isValid, message)
        this.phone = phoneNumber.formatInternational()
      }else{
        this.$refs.phoneNum.setValidity(false, 'Invalid phone number')
      }
      this.organisation = user.attributes ? user.attributes['custom:organisation'] : null

    }).catch((error) => {
      console.log(error)
    });

    if (this.$store.getters.getMe == null) {
      await this.$store.dispatch('getMe', this.$store.state.user.username)
    }
    this.apps = this.$store.getters.getMe && this.$store.getters.getMe.apps ? this.$store.getters.getMe.apps : null

    let appName = this.$route.query.name
    console.log('App Name: ', appName)
    if(this.apps && this.apps.length > 0){
      let appIndex = this.apps.findIndex(app => app.name === appName)
      if (appIndex !== -1) {
        this.backTo = this.apps[appIndex]
      }
    }


  },
  methods:{
    callBack(){
      if (this.backTo) {
        window.location.replace(this.backTo.url)
      } else {
        this.$router.push('/')
      }
    },
    onPhoneChanged(value) {
      console.log('On phone changed: ', value)
      if (value.length > 6) {
        const phoneNumber = parsePhoneNumber(value)
        console.log('Phone', phoneNumber)
        if (phoneNumber.country) {
          let isPossible = isPossiblePhoneNumber(value, phoneNumber.country) === true
          let isValid = isValidPhoneNumber(value, phoneNumber.country) === true
          let message = isValid ? '' : 'Invalid phone number'
          console.log('Type', phoneNumber.getType())
          this.$refs.phoneNum.setValidity(isValid, message)
        } else {
          this.$refs.phoneNum.setValidity(false, 'Invalid phone number')
        }
        this.phone = phoneNumber.formatInternational()
      } else {
        this.$refs.phoneNum.setValidity(false, 'Invalid phone number')
      }
    },
    async changeProfile(){
      console.log('On change profile')
      if(!this.isValid()){
        this.$buefy.notification.open({
          message: 'Invalid form values',
          type: 'is-danger'
        })
        return
      }

      this.$emit('showLoading', true)

      let phoneNum = this.phone ? this.phone.replace(/\s+/g, '') : ''
      console.log('Phone num: ', phoneNum)

      try {
        const user = await this.$auth.currentAuthenticatedUser();
        const result = await this.$auth.updateUserAttributes(user, {
          name: this.name,
          phone_number: phoneNum,
          'custom:organisation': this.organisation
        });
        console.log(result);
        this.$buefy.notification.open({
          message: 'Profile successfully changed',
          type: 'is-success'
        })

        setTimeout(() => {
          this.callBack()
        }, 1000)
      } catch (error) {
        this.$buefy.notification.open({
          duration: 5000,
          message: error.message,
          position: 'is-bottom-right',
          type: 'is-danger',
          hasIcon: false
        })
      }
      this.$emit('showLoading', false)
    },
    isValid(){
      if(this.$refs.name && this.$refs.phoneNum){
        return this.$refs.name.value && this.$refs.name.value.length > 5 && this.$refs.phoneNum.isValid
      }

      return false

    },
  }
}
</script>

<style lang="scss" src="./style.scss" scoped>

</style>