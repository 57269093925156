<template src="./template.html"></template>

<script>
export default {
  name: "change-password",
  data() {
    return {
      oldPass: null,
      password: null,
      confirmPass: null,
      apps: null,
      backTo: null
    }
  },
  async mounted() {
    await this.getApp()
  },
  methods: {
    async getApp() {
      let appName = this.$route.query.name
      if (appName) {
        this.$emit('showLoading', true)
        if (this.$store.state.user === null) {
          await this.$auth.currentAuthenticatedUser().then(async (user) => {
            this.$store.commit('saveUserData', user)
          }).catch((error) => {
            console.log(error)
          });
        }
        if (this.$store.getters.getMe == null) {
          await this.$store.dispatch('getMe', this.$store.state.user.username)
        }
        this.apps = this.$store.getters.getMe && this.$store.getters.getMe.apps ? this.$store.getters.getMe.apps : null

        console.log('App Name: ', appName)
        if(this.apps && this.apps.length > 0) {
          let appIndex = this.apps.findIndex(app => app.name === appName)
          if (appIndex !== -1) {
            this.backTo = this.apps[appIndex]
          }
        }
        this.$emit('showLoading', false)
      }
    },
    callBack() {
      if (this.backTo) {
        window.location.replace(this.backTo.url)
      } else {
        this.$router.push('/')
      }
    },
    async setNewPassword() {
      console.log('Set new pass:')
      if (this.$refs.pass.isValid && this.$refs.confirmPass.isValid && this.$refs.oldPass.value) {
        if (this.password && this.confirmPass) {
          if (this.password !== this.confirmPass) {
            this.showError('Passwords not match')
            return
          }

          this.$emit('showLoading', true)

          try {
            const user = await this.$auth.currentAuthenticatedUser();
            const data = await this.$auth.changePassword(user, this.oldPass, this.confirmPass);
            console.log(data);
            this.$buefy.notification.open({
              message: 'Password successfully changed',
              type: 'is-success'
            })

            setTimeout(() => {
              this.callBack()
            }, 1000)
          } catch (error) {
            this.$buefy.notification.open({
              duration: 5000,
              message: error.message,
              position: 'is-bottom-right',
              type: 'is-danger',
              hasIcon: false
            })
          }
          this.$emit('showLoading', false)
        }
      }
    },
    isValid() {
      if (this.$refs.pass && this.$refs.confirmPass) {
        return this.$refs.pass.isValid && this.$refs.confirmPass.isValid && this.$refs.oldPass.value && this.$refs.oldPass.value.length > 0
      }

      return false

    },
    showError(message) {
      this.$emit('showLoading', false)
      this.$buefy.toast.open({
        message: message,
        type: 'is-danger'
      })
    }
  }
}
</script>

<style lang="scss" src="./style.scss" scoped></style>